.container {
  min-height: 100vh;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.login-container {
  margin: 0 auto;
}

.finzi-logo-container {
  padding: 20px;
  text-align: center;
}

.finzi-logo-container img {
  width: 80%;
}