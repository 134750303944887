.container {
  min-height: 100vh;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content-body {
  background-color: white;
  padding: 10vh;
}

.buttonSecundaryColor {
  background-color: #4caf50;
}
