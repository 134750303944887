@import '~antd/dist/antd.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .fz-input {
    @apply block w-full mt-1 text-sm focus:border-purple-400 border border-[#e2e8f0] rounded-md focus:outline-none p-2;
  }
  .btn{
    @apply block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white bg-purple-600 border border-transparent rounded-lg focus:outline-none;
  }
}